{
  "name": "ringcentral-web-phone",
  "version": "1.0.3",
  "homepage": "https://github.com/ringcentral/ringcentral-web-phone",
  "bugs": {
    "url": "https://github.com/ringcentral/ringcentral-web-phone/issues"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/ringcentral/ringcentral-web-phone.git"
  },
  "license": "MIT",
  "author": {
    "name": "RingCentral, Inc.",
    "email": "devsupport@ringcentral.com"
  },
  "contributors": [
    {
      "name": "Kirill Konshin"
    },
    {
      "name": "Elias Sun"
    },
    {
      "name": "Vyshakh Babji"
    },
    {
      "name": "Yatin Gera"
    },
    {
      "name": "Tyler Liu"
    },
    {
      "name": "Embbnux Ji"
    }
  ],
  "main": "./lib/src/index.js",
  "types": "./lib/src/index.d.ts",
  "scripts": {
    "build": "yarn tsc && rmdir /s /q docs && mkdir docs && parcel build demo/index.html demo/callback.html --dist-dir docs --public-url ./",
    "lint": "eslint --fix '**/*.{ts,tsx,js,jsx}' && prettier --write . && sort-package-json",
    "start": "npm run clean && npm run serve",
    "clean": "npm run clean:parcel",
    "clean:parcel": "rm -rf .parcel-cache || rimraf .parcel-cache",
    "serve": "parcel demo/index.html demo/callback.html --dist-dir docs",
    "test": "jest src/mediaStreams.spec.ts",
    "test2": "NODE_OPTIONS=--require=dotenv-override-true/config playwright test test/index.spec.ts --workers=1",
    "test:coverage": "cat .coverage/lcov.info | coveralls -v"
  },
  "dependencies": {
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@fortawesome/react-fontawesome": "^0.2.2",
    "react-router-dom": "^6.24.1",
    "sip.js": "^0.21.2"
  },
  "devDependencies": {
    "@faker-js/faker": "^8.4.1",
    "@playwright/test": "^1.44.1",
    "@rc-ex/core": "^1.3.15",
    "@ringcentral/sdk": "^5.0.1",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.14.2",
    "@typescript-eslint/eslint-plugin": "^7.13.0",
    "@typescript-eslint/parser": "^7.13.0",
    "bootstrap": "3.4.1",
    "buffer": "^6.0.3",
    "coveralls": "^3.1.1",
    "crypto-browserify": "^3.12.0",
    "dotenv-override-true": "^6.2.2",
    "eslint": "8.57.0",
    "eslint-config-alloy": "^5.1.2",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-jest": "^28.6.0",
    "eslint-plugin-prettier": "^5.1.3",
    "events": "^3.3.0",
    "http-server": "^14.1.1",
    "jest": "^29.7.0",
    "jquery": "^3.7.1",
    "parcel": "^2.12.0",
    "prettier": "^3.3.1",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "rimraf": "^6.0.1",
    "sort-package-json": "^2.10.0",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "ts-jest": "^29.1.4",
    "ts-node": "^10.9.2",
    "ttpt": "^0.9.6",
    "typescript": "^5.4.5",
    "vm-browserify": "^1.1.2",
    "wait-for-async": "^0.6.1",
    "yarn-upgrade-all": "^0.7.2",
    "zip-folder": "^1.0.0"
  },
  "preferGlobal": false,
  "targets": {
    "main": false,
    "module": false,
    "types": false,
    "browser": false
  },
  "yarn-upgrade-all": {
    "ignore": [
      "bootstrap",
      "eslint"
    ]
  }
}
