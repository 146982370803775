import $ from 'jquery';
import { SDK } from '@ringcentral/sdk';
import 'bootstrap/dist/css/bootstrap.min.css';
import type Platform from '@ringcentral/sdk/lib/platform/Platform';
import { SessionState } from 'sip.js';

import WebPhone from '../src/index';
import incomingAudio from 'url:./audio/incoming.ogg';
import outgoingAudio from 'url:./audio/outgoing.ogg';
import type { WebPhoneInvitation } from '../src/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faPhone } from '@fortawesome/free-solid-svg-icons';
import img from '../src/icon_phone.png';
import axios from 'axios';
global.jQuery = $;
import('bootstrap');


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let paramNumber = '';
let parmName = '';
let urlKey = ' ';

urlParams.forEach((val, key) => {
  if (key === 'name') {
    parmName = val;
  } else if (key === 'call_history' || key === 'call_number') {
    paramNumber = val;
  }

  if (key === 'call_number') {
    urlKey = key;
  } else if (key === 'call_history') {
    urlKey = key;
  }

  // urlKey = key;
})

// console.log(parmName, paramNumber)
// console.log(urlKey)

if (paramNumber) {
  console.log(`Value of 'name' parameter: ${paramNumber}`);
  const valueToStore = paramNumber ?? '';
  localStorage.setItem('webPhoneLastNumber', valueToStore);
}




$(() => {
  let sdk: SDK;
  let platform: Platform;
  let webPhone: WebPhone;

  let logLevel: 0 | 1 | 2 | 3 = 0;
  let extension: any;
  let primaryNumber: string;
  const $app = $('#app');

  const $loginTemplate = $('#template-login');
  const $authFlowTemplate = $('#template-auth-flow');
  const $callTemplate = $('#template-call');
  const $incomingTemplate = $('#template-incoming');
  const $acceptedTemplate = $('#template-accepted');
  const $callDetailsTemplate = $('#template-details');
  const $history_template = $('#template-history');

  const remoteVideoElement = document.getElementById('remoteVideo') as HTMLMediaElement;
  const localVideoElement = document.getElementById('localVideo') as HTMLMediaElement;
  let outboundCall = true;
  let confSessionId = '';
  let hangUpHistory = false;
  let hangUpHistory2 = false;
  /**
   * @param {jQuery|HTMLElement} $tpl
   * @return {jQuery|HTMLElement}
   */


  function cloneTemplate($tpl) {
    return $($tpl.html());
  }

  function formatDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  }

  // No 5     // Fetch caling history
  // async function fetchCallLog(access_token) {

  //   const $historys = cloneTemplate($history_template);
  //   const $form = cloneTemplate($callTemplate);

  //   const $homeCountry = $form.find('input[name=homeCountry]').eq(0);
  //   const $name = $historys.find('.name').eq(0);
  //   const $number = $historys.find('.number').eq(0);
  //   const $avatar = $historys.find('.avatar').eq(0);
  //   const $historys_call_button = $historys.find('.header').eq(0);

  //   let avatar_name = parmName;
  //   $avatar.text(avatar_name.charAt(0));
  //   $name.text(parmName);
  //   $number.text('+' + paramNumber);

  //   $app.append($historys);
    

  //   var url = '';
  //   url = `https://platform.ringcentral.com/restapi/v1.0/account/~/extension/~/call-log?phoneNumber=${paramNumber}&dateFrom=2000-01-01&perPage=1000`;

  //   const headers = {
  //     'Authorization': 'Bearer ' + access_token,
  //     'Accept': 'application/json',
  //   };


  //   try {
  //     const response = await fetch(url, {
  //       method: 'GET',
  //       headers: headers,
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();

  //     let callHistoryHtml = '';
  //     let  toPhoneNumber = data.records[1].to.phoneNumber ? data.records[1].to.phoneNumber : paramNumber;
  //     let  fromPhoneNumber = data.records[1].from.phoneNumber ? data.records[1].from.phoneNumber : paramNumber; // Get the phone number from record
      
  //     if (toPhoneNumber.startsWith('+') || fromPhoneNumber.startsWith('+')) {
  //       toPhoneNumber = toPhoneNumber.substring(1);
  //     }

  //     console.log(data.records[1].to)

  //     data.records.forEach(record => {

  //        if (paramNumber == toPhoneNumber || paramNumber == fromPhoneNumber) {
  //         if (record.direction === 'Outbound') {
  //           if (record.result == 'Call connected') {
  //             callHistoryHtml += `
  //                   <div class="call-history" style='width: 80%; float: right;'>
  //                       <div class="call-outgoing call" style="background: #bcd0d9a8">
  //                           <div>
  //                               <div class="type">No answer</div>
  //                               <div class="time">${new Date(record.startTime).toLocaleString()}</div>
  //                           </div>
  //                           <div>
  //                               <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
  //                               <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
  //                           </div>
  //                       </div>
  //                   </div>`;
  //           } else if (record.result === 'No Answer' || record.result === 'Hang Up' || record.result === 'Missed' || record.result === 'Voicemail' || record.result === 'IP Phone Offline') {
  //             callHistoryHtml += `
  //             <div class="call-history" style='width: 80%; float: right;'>
  //                 <div class="call-outgoing call" style="background: #ffdfdf;">
  //                     <div>
  //                         <div class="type">Outgoing failed</div>
  //                         <div class="time">${new Date(record.startTime).toLocaleString()}</div>
  //                     </div>
  //                     <div>
  //                         <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
  //                         <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
  //                     </div>
  //                 </div>
  //             </div>`;
  //           } else if (record.result == 'Accepted') {
  //             callHistoryHtml += `
  //             <div class="call-history" style='width: 80%; float: right;'>
  //                 <div class="call-outgoing call" style="background: #ace3a287; ">
  //                     <div>
  //                         <div class="type"> Outgoing success </div>
  //                         <div class="time">${new Date(record.startTime).toLocaleString()}</div>
  //                     </div>
  //                     <div>
  //                         <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
  //                         <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
  //                     </div>
  //                 </div>
  //             </div>`;
  //           }
  //         } else {
  //           callHistoryHtml += `
  //                   <div class="call-history" style='width: 80%; float: left;'>
  //                       <div class="call-incoming call" style=' background: #a7f1d4;'>
  //                           <div>
  //                               <div class="type">Incoming call</div>
  //                               <div class="time">${new Date(record.startTime).toLocaleString()}</div>
  //                           </div>
  //                           <div>
  //                               <div class="recoding_time">( ${formatDuration(record.duration)} s )</div>
  //                               <div class="recoding_time"> ${record.from.phoneNumber ? record.from.phoneNumber : record.to.phoneNumber} </div>
  //                           </div>
  //                       </div>
  //                   </div>`;
  //         }
  //       } else {
  //         callHistoryHtml = `
  //         <div class="call-history" style='width: 80%; margin: 0 auto; '>
  //             <div class="call-outgoing call" style="background: #ffdfdf;">
  //                 History not found.
  //             </div>
  //         </div>`;
  //       }

  //     });

  //     $historys.find('#btn-call').on('click', (e) => {
  //       let local_number = localStorage.getItem('webPhoneLastNumber');
  //       makeCall(local_number, $homeCountry.val());
  //       hangUpHistory = true;
  //       hangUpHistory2 = true;
  //       $($historys_call_button).hide();
  //     })

  //     const divElement = $('<div>');
  //     divElement.css({ 'height': '500px', 'overflow-y': 'scroll', 'margin': '0 2px 0 0' })
  //     divElement.html(callHistoryHtml);

  //     $app.show();
  //       $app.append(divElement);

  //   } catch (error) {
  //     console.error('Fetch Error:', error);
  //   }


  // }

  async function fetchCallLog(access_token) {

    const $historys = cloneTemplate($history_template);
    const $form = cloneTemplate($callTemplate);

    const $homeCountry = $form.find('input[name=homeCountry]').eq(0);
    const $name = $historys.find('.name').eq(0);
    const $number = $historys.find('.number').eq(0);
    const $avatar = $historys.find('.avatar').eq(0);
    const $historys_call_button = $historys.find('.header').eq(0);

    let avatar_name = parmName;
    $avatar.text(avatar_name.charAt(0));
    $name.text(parmName);
    $number.text('+' + paramNumber);

    $app.append($historys);
    
    const url = `https://platform.ringcentral.com/restapi/v1.0/account/~/extension/~/call-log?phoneNumber=${paramNumber}&dateFrom=2000-01-01&perPage=1000`;

    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Accept': 'application/json',
    };

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      let callHistoryHtml = '';
      let hasValidRecords = false;

      data.records.forEach(record => {
        const toPhoneNumber = record.to && record.to.phoneNumber ? record.to.phoneNumber : paramNumber;
        const fromPhoneNumber = record.from && record.from.phoneNumber ? record.from.phoneNumber : paramNumber;

        // Remove the '+' sign if it exists
        const cleanToPhoneNumber = toPhoneNumber.startsWith('+') ? toPhoneNumber.substring(1) : toPhoneNumber;
        const cleanFromPhoneNumber = fromPhoneNumber.startsWith('+') ? fromPhoneNumber.substring(1) : fromPhoneNumber;

        if (paramNumber === cleanToPhoneNumber || paramNumber === cleanFromPhoneNumber) {
          hasValidRecords = true;

          if (record.direction === 'Outbound') {
            if (record.result === 'Call connected') {
              callHistoryHtml += `
                    <div class="call-history" style='width: 80%; float: right;'>
                        <div class="call-outgoing call" style="background: #bcd0d9a8">
                            <div>
                                <div class="type">No answer</div>
                                <div class="time">${new Date(record.startTime).toLocaleString()}</div>
                            </div>
                            <div>
                                <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
                                <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
                            </div>
                        </div>
                    </div>`;
            } else if (['No Answer', 'Hang Up', 'Missed', 'Voicemail', 'IP Phone Offline'].includes(record.result)) {
              callHistoryHtml += `
              <div class="call-history" style='width: 80%; float: right;'>
                  <div class="call-outgoing call" style="background: #ffdfdf;">
                      <div>
                          <div class="type">Outgoing failed</div>
                          <div class="time">${new Date(record.startTime).toLocaleString()}</div>
                      </div>
                      <div>
                          <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
                          <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
                      </div>
                  </div>
              </div>`;
            } else if (record.result === 'Accepted') {
              callHistoryHtml += `
              <div class="call-history" style='width: 80%; float: right;'>
                  <div class="call-outgoing call" style="background: #ace3a287; ">
                      <div>
                          <div class="type"> Outgoing success </div>
                          <div class="time">${new Date(record.startTime).toLocaleString()}</div>
                      </div>
                      <div>
                          <div class="recoding_time">( ${formatDuration(record.duration)} )</div>
                          <div class="recoding_time"> ${record.to.phoneNumber ? record.to.phoneNumber : record.from.phoneNumber} </div>
                      </div>
                  </div>
              </div>`;
            }
          } else {
            callHistoryHtml += `
                    <div class="call-history" style='width: 80%; float: left;'>
                        <div class="call-incoming call" style=' background: #a7f1d4;'>
                            <div>
                                <div class="type">Incoming call</div>
                                <div class="time">${new Date(record.startTime).toLocaleString()}</div>
                            </div>
                            <div>
                                <div class="recoding_time">( ${formatDuration(record.duration)} s )</div>
                                <div class="recoding_time"> ${record.from.phoneNumber ? record.from.phoneNumber : record.to.phoneNumber} </div>
                            </div>
                        </div>
                    </div>`;
          }
        }
      });

      if (!hasValidRecords) {
        callHistoryHtml = `
          <div class="call-history" style='width: 80%; margin: 0 auto; '>
              <div class="call-outgoing call" style="background: #ffdfdf;">
                  History not found.
              </div>
          </div>`;
      }

      $historys.find('#btn-call').on('click', (e) => {
        let local_number = localStorage.getItem('webPhoneLastNumber');
        makeCall(local_number, $homeCountry.val());
        hangUpHistory = true;
        hangUpHistory2 = true;
        $($historys_call_button).hide();
      });

      const divElement = $('<div>');
      divElement.css({ 'height': '500px', 'overflow-y': 'scroll', 'margin': '0 2px 0 0' });
      divElement.html(callHistoryHtml);

      $app.show();
      $app.append(divElement);

    } catch (error) {
      console.error('Fetch Error:', error);
    }
}


  // No 2   // Login us into website
  function login(server: string, clientId: string, clientSecret: string, jwtToken: string, ll: string) {
    sdk = new SDK({
      clientId,
      clientSecret,
      server,
    });

    platform = sdk.platform();

    // fetchCallLogHistory(platform);

    platform
      .login({
        jwt: jwtToken,
      })
      .then(() => {
        // Save session details to localStorage
        localStorage.setItem('webPhoneServer', server);
        localStorage.setItem('webPhoneClientId', clientId);
        localStorage.setItem('webPhoneClientSecret', clientSecret);
        localStorage.setItem('webPhoneJwtToken', jwtToken);
        localStorage.setItem('webPhoneLogLevel', ll);
        localStorage.setItem('webPhoneLoggedIn', 'true');

        return postLogin(server, clientId, clientSecret, jwtToken, ll);
      })
      .catch((e) => {
        console.error(e.stack || e);
      });

  }

  function show3LeggedLogin(server, clientId, clientSecret, ll) {
    const $redirectUri = decodeURIComponent(window.location.href.split('login', 1) + 'callback.html');

    console.log('The redirect uri value :', $redirectUri);

    sdk = new SDK({
      clientId,
      clientSecret,
      server,
      redirectUri: $redirectUri,
    });

    platform = sdk.platform();

    const loginUrl = platform.loginUrl();

    platform
      .loginWindow({ url: loginUrl }) // this method also allows to supply more options to control window position
      .then(platform.login.bind(platform))
      .then(() => {
        return postLogin(server, clientId, clientSecret, '', ll);
      })
      .catch((e) => {
        console.error(e.stack || e);
      });



  }

  function fetchCallLogHistory(platform) {

    console.log('dfdsfsfsdfsd');
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 90); // 90 days ago
    const dateTo = new Date();

    platform
      .get('/account/~/extension/~/call-log', {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
      })
      .then((response) => {
        const callLogs = response.json().records;
        console.log('Call Logs:', callLogs);
        // Handle the call logs as needed, e.g., display them in the UI
      })
      .catch((e) => {
        console.error(e.stack || e);
      });
  }

  function postLogin(server, clientId, clientSecret, jwtToken, ll) {
    logLevel = ll;

    localStorage.setItem('webPhoneServer', server || '');
    localStorage.setItem('webPhoneclientId', clientId || '');
    localStorage.setItem('webPhoneclientSecret', clientSecret || '');
    if (jwtToken && jwtToken.length > 0) {
      localStorage.setItem('webPhoneJwtToken', jwtToken);
    }
    localStorage.setItem('webPhoneLogLevel', (logLevel || 0).toString());

    return platform
      .get('/restapi/v1.0/account/~/extension/~')
      .then((res) => res.json())
      .then((res) => {
        extension = res;
        console.log('Extension info', extension);
        return platform.get('/restapi/v1.0/account/~/extension/~/phone-number');
      })
      .then((res) => res.json())
      .then((r) => {
        primaryNumber = r.records.filter((r) => r.primary === true)[0].phoneNumber;
      })
      .then(() => {
        return platform.post('/restapi/v1.0/client-info/sip-provision', {
          sipInfo: [
            {
              transport: 'WSS',
            },
          ],
        });
      })
      .then((res) => res.json())
      .then(register)
      .then(makeCallForm)
      .catch((e) => {
        console.error('Error in main promise chain');
        console.error(e.stack || e);
      });
  }

  function register(data) {
    webPhone = new WebPhone(data, {
      enableDscp: true,
      clientId: localStorage.getItem('webPhoneclientId')!,
      audioHelper: {
        enabled: true,
        incoming: incomingAudio,
        outgoing: outgoingAudio,
      },
      logLevel,
      appName: 'WebPhoneDemo',
      appVersion: '1.0.0',
      media: {
        remote: remoteVideoElement,
        local: localVideoElement,
      },
      enableQos: true,
      enableMediaReportLogging: true,
    });
    global.webPhone = webPhone; // for debugging

    webPhone.userAgent.audioHelper.setVolume(0.3);
    webPhone.userAgent.on('invite', onInvite);
    webPhone.userAgent.on('connecting', () => {
      console.log('UA connecting');
    });
    webPhone.userAgent.on('connected', () => {
      console.log('UA Connected');
    });
    webPhone.userAgent.on('disconnected', () => {
      console.log('UA Disconnected');
    });
    webPhone.userAgent.on('registered', () => {
      console.log('UA Registered');
    });
    webPhone.userAgent.on('unregistered', () => {
      console.log('UA Unregistered');
    });
    webPhone.userAgent.on('registrationFailed', function () {
      console.log('UA RegistrationFailed', arguments);
    });
    webPhone.userAgent.on('message', function () {
      console.log('UA Message', arguments);
    });
    webPhone.userAgent.transport.on('switchBackProxy', () => {
      console.log('switching back to primary outbound proxy');
      webPhone.userAgent.transport.reconnect();
    });
    webPhone.userAgent.transport.on('closed', () => {
      console.log('WebSocket closed.');
    });
    webPhone.userAgent.transport.on('transportError', () => {
      console.log('WebSocket transportError occured');
    });
    webPhone.userAgent.transport.on('wsConnectionError', () => {
      console.log('WebSocket wsConnectionError occured');
    });
    return webPhone;
  }

  function onInvite(session: WebPhoneInvitation) {
    const $modal = cloneTemplate($incomingTemplate).modal({
      backdrop: 'static',
    });

    session.stateChange.addListener((newState: SessionState) => {
      switch (newState) {
        case SessionState.Initial: {
          console.log('Initial');
          break;
        }
        case SessionState.Establishing: {
          console.log('Establishing');
          break;
        }
        case SessionState.Established: {
          console.log('Established');
          break;
        }
        case SessionState.Terminating: {
          console.log('Terminating');
          $modal.modal('hide');
          break;
        }
        case SessionState.Terminated: {
          console.log('Terminated');
          $modal.modal('hide');
          break;
        }
      }
    });

    outboundCall = false;
    console.log('EVENT: Invite', session.request);
    console.log('To', session.request.to.displayName, session.request.to.friendlyName);
    console.log('From', session.request.from.displayName, session.request.from.friendlyName);

    if (session.request.headers['Alert-Info'] && session.request.headers['Alert-Info'][0].raw === 'Auto Answer') {
      session
        .accept()
        .then(() => {
          onAccepted(session);
        })
        .catch((e) => {
          console.error('Accept failed', e.stack || e);
        });
    } else {
      $modal.find('.answer').on('click', () => {
        $modal.find('.before-answer').css('display', 'none');
        $modal.find('.answered').css('display', '');
        session
          .accept()
          .then(() => {
            $modal.modal('hide');
            onAccepted(session);
          })
          .catch((e) => {
            console.error('Accept failed', e.stack || e);
          });
      });

      $modal.find('.decline').on('click', () => {
        session.reject();
        $modal.modal('hide');
      });

      $modal.find('.toVoicemail').on('click', () => {
        session.toVoicemail!();
        $modal.modal('hide');
      });

      $modal.find('.forward-form').on('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        session.forward!($modal.find('input[name=forward]').val().trim())
          .then(() => {
            console.log('Forwarded');
            $modal.modal('hide');
          })
          .catch((e2) => {
            console.error('Forward failed', e2.stack || e2);
          });
      });

      $modal.find('.reply-form').on('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        session.replyWithMessage!({
          replyType: 0,
          replyText: $modal.find('input[name=reply]').val(),
        })
          .then(() => {
            console.log('Replied');
            $modal.modal('hide');
          })
          .catch((e2) => {
            console.error('Reply failed', e2.stack || e2);
          });
      });
    }
  }

  const activeCallInfoTemplate = () => ({
    id: '',
    from: '',
    to: '',
    direction: '',
    sipData: {
      toTag: '',
      fromTag: '',
    },
  });

  function captureActiveCallInfo(session) {
    const direction = outboundCall ? 'Outbound' : 'Inbound';
    const activeCallInfo = activeCallInfoTemplate();
    activeCallInfo.from = session.request.from.uri.user;
    activeCallInfo.to = session.request.to.uri.user;
    activeCallInfo.direction = direction;
    activeCallInfo.id = session.dialog.id.callId;
    activeCallInfo.sipData.fromTag = session.dialog.remoteTag;
    activeCallInfo.sipData.toTag = session.dialog.localTag;
    if (!localStorage.getItem('activeCallInfo')) {
      localStorage.setItem('activeCallInfo', JSON.stringify(activeCallInfo));
    }
  }

  function onAccepted(session) {
    const $modal = cloneTemplate($acceptedTemplate);
    const $details = cloneTemplate($callDetailsTemplate).modal();
    const $form = cloneTemplate($callTemplate);

    const $collContainer = $details.find('.header').eq(0);
    const $homeCountry = $form.find('input[name=homeCountry]').eq(0);
    const $info = $details.find('.time').eq(0);
    const $isCalling = $details.find('.type').eq(0);
    const $recode_time = $details.find('.recoding_time').eq(0);
    const $dtmf = $modal.find('input[name=dtmf]').eq(0);
    const $transfer = $modal.find('input[name=transfer]').eq(0);
    const $flip = $modal.find('input[name=flip]').eq(0);

    const $callButton = $details.find('#btn-call').eq(0);
    const $callButtonImg = $details.find('#btn-call img').eq(0);
    const $name_user = $details.find('.name').eq(0);
    const $number_user = $details.find('.number').eq(0);
    const $first_latter = $details.find('.avatar').eq(0);

    const firstLetter = parmName.charAt(0);

    $callButtonImg.attr('src', `${img}`);
    $name_user.text(`${parmName}`)
    $first_latter.text(`${firstLetter}`)
    $number_user.text(`+${paramNumber ? paramNumber : "123"}`)

    let recordingStarted = false;

    const interval = setInterval(() => {
      const time = session.startTime ? Math.round((Date.now() - session.startTime) / 1000) + 's' : 'Ringing';
      const formattedStartTime = session.startTime ? new Date(session.startTime).toLocaleString() : 'Not started';
      $info.text(`${formattedStartTime}\n`);
      $recode_time.text(` (${time})`);
      $isCalling.text(`Ongoing call`);

      // const time = session.startTime ? Math.round((Date.now() - session.startTime) / 1000) + 's' : 'Ringing';
      // const formattedStartTime = session.startTime ? new Date(session.startTime).toLocaleString() : 'Not started';
      // $info.text(`${formattedStartTime}\n`);
      // $recode_time.text(` ( ${time} ) `);
      // $isCalling.text(`Ongoing call`);

      console.log(session.startTime)

      // Automatically start recording once session is established
      if (session.state === SessionState.Established && !recordingStarted) {
        session.startRecord()
          .then(() => {
            console.log('Recording Started');
            recordingStarted = true;
          })
          .catch((e) => {
            console.error('Recording Start failed', e.stack || e);
          });
      }
    }, 1000);

    $details.find('#btn-call').on('click', (e) => {
      let local_number = localStorage.getItem('webPhoneLastNumber');
      makeCall(local_number, $homeCountry.val());
      hangUpHistory = true;
      hangUpHistory2 = true;

      if (hangUpHistory) {
        $details.modal('hide');
      }

      if (hangUpHistory2) {
        $collContainer.hide();
        $collContainer.css({ 'display': 'none' });
      }

    })


    session.stateChange.addListener((newState) => {
      if (newState === SessionState.Terminated) {
        clearInterval(interval);
      }
    });

    $(document).ready(function () {
      $('.modal-backdrop').removeClass('modal-backdrop');
    });

    function closeModal() {
      clearInterval(interval);
      $modal.modal('hide');
    }

    $modal.find('.increase-volume').on('click', () => {
      session.userAgent.audioHelper.setVolume(
        (session.userAgent.audioHelper.volume !== null ? session.userAgent.audioHelper.volume : 0.5) + 0.1,
      );
    });

    $modal.find('.decrease-volume').on('click', () => {
      session.userAgent.audioHelper.setVolume(
        (session.userAgent.audioHelper.volume !== null ? session.userAgent.audioHelper.volume : 0.5) - 0.1,
      );
    });

    $modal.find('.mute').on('click', () => {
      session.mute();
    });

    $modal.find('.unmute').on('click', () => {
      session.unmute();
    });

    $modal.find('.hold').on('click', () => {
      session
        .hold()
        .then(() => {
          console.log('Holding');
        })
        .catch((e) => {
          console.error('Holding failed', e.stack || e);
        });
    });

    $modal.find('.unhold').on('click', () => {
      session
        .unhold()
        .then(() => {
          console.log('UnHolding');
        })
        .catch((e) => {
          console.error('UnHolding failed', e.stack || e);
        });
    });

    $modal.find('.startRecord').on('click', () => {
      session
        .startRecord()
        .then(() => {
          console.log('Recording Started');
        })
        .catch((e) => {
          console.error('Recording Start failed', e.stack || e);
        });
    });

    // $modal.find('.stopRecord').on('click', () => {
    //   session
    //     .stopRecord()
    //     .then(() => {
    //       console.log('Recording Stopped');
    //     })
    //     .catch((e) => {
    //       console.error('Recording Stop failed', e.stack || e);
    //     });
    // });

    $modal.find('.park').on('click', () => {
      session
        .park()
        .then(() => {
          console.log('Parked');
        })
        .catch((e) => {
          console.error('Park failed', e.stack || e);
        });
    });

    $modal.find('.transfer-form').on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      session
        .transfer($transfer.val().trim())
        .then(() => {
          console.log('Transferred');
          $modal.modal('hide');
        })
        .catch((e2) => {
          console.error('Transfer failed', e2.stack || e2);
        });
    });

    $modal.find('.transfer-form button.warm').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      session.hold().then(() => {
        console.log('Placing the call on hold, initiating attended transfer');
        const newSession = session.userAgent.invite($transfer.val().trim());
        newSession.once('established', () => {
          console.log('New call initated. Click Complete to complete the transfer');
          $modal.find('.transfer-form button.complete').on('click', () => {
            session
              .warmTransfer(newSession)
              .then(() => {
                console.log('Warm transfer completed');
              })
              .catch((e2) => {
                console.error('Transfer failed', e2.stack || e2);
              });
          });
        });
      });
    });

    $modal.find('.flip-form').on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      session
        .flip($flip.val().trim())
        .then(() => {
          console.log('Flipped');
        })
        .catch((e2) => {
          console.error('Flip failed', e2.stack || e2);
        });
      $flip.val('');
    });

    $modal.find('.dtmf-form').on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      session.dtmf($dtmf.val().trim());
      $dtmf.val('');
    });

    const $startConfButton = $modal.find('.startConf');

    $startConfButton.on('click', () => {
      initConference();
    });

    $details.find('.hangup').on('click', () => {
      session.dispose();
      $isCalling.text(`Call ended`);
      // if (hangUpHistory) {
      //   $details.modal('hide');
      // }
    });

    session.stateChange.addListener((newState: SessionState) => {
      switch (newState) {
        case SessionState.Initial: {
          console.log('Initial');
          break;
        }
        case SessionState.Establishing: {
          console.log('Establishing');
          break;
        }
        case SessionState.Established: {
          console.log('Established');
          captureActiveCallInfo(session);
          session
            .startRecord()
            .then(() => {
              console.log('Recording Started');
            })
            .catch((e) => {
              console.error('Recording Start failed', e.stack || e);
            });
          break;
        }
        case SessionState.Terminating: {
          console.log('Terminating');
          closeModal();
          break;
        }
        case SessionState.Terminated: {
          console.log('Terminated');
          closeModal();
          localStorage.setItem('activeCallInfo', '');
          break;
        }
      }
    });

    session.on('refer', () => {
      console.log('Event: Refer');
      closeModal();
    });
    session.on('replaced', (newSession) => {
      console.log('Event: Replaced: old session', session, 'has been replaced with', newSession);
      closeModal();
      onAccepted(newSession);
    });
    session.on('dtmf', () => {
      console.log('Event: DTMF');
    });
    session.on('muted', () => {
      console.log('Event: Muted');
    });
    session.on('unmuted', () => {
      console.log('Event: Unmuted');
    });
  }

  // No 4   // Auto calling 
  function makeCall(number, homeCountryId) {

    outboundCall = true;
    // eslint-disable-next-line no-param-reassign
    homeCountryId = homeCountryId || extension?.regionalSettings?.homeCountry?.id || null;

    const session = webPhone.userAgent.invite(number, {
      fromNumber: primaryNumber,
      homeCountryId,
    });
    // console.log("number from makeCall " + number )
    // console.log("fromNumber from makeCall " + session.request.from.uri.user);
    onAccepted(session);
  }

  function switchCall() {
    const activeCallItem = JSON.parse(localStorage.getItem('activeCallInfo')!);
    console.log('Switching active call to current tab: ', activeCallItem);
    const session = webPhone.userAgent.switchFrom(activeCallItem, {});
    onAccepted(session);
  }

  let onConference = false;

  function initConference() {
    if (!onConference) {
      getPresenceActiveCalls()
        .then((res) => res.json())
        .then((response) => {
          const partyId = response.activeCalls[0].partyId;
          const telephonySessionId = response.activeCalls[0].telephonySessionId;
          getConfVoiceToken().then((voiceToken) => {
            startConferenceCall(voiceToken, partyId, telephonySessionId);
            onConference = true;
          });
        });
    } else {
      alert('A conference is already in progress');
    }
  }

  function getPresenceActiveCalls() {
    return platform.get('/restapi/v1.0/account/~/extension/~/presence?detailedTelephonyState=true');
  }

  function getConfVoiceToken() {
    return platform
      .post('/restapi/v1.0/account/~/telephony/conference', {})
      .then((res) => res.json())
      .then((res) => {
        confSessionId = res.session.id;
        return res.session.voiceCallToken;
      });
  }

  function startConferenceCall(voiceToken, partyId, telephonySessionId) {
    const session = webPhone.userAgent.invite(voiceToken, {
      fromNumber: primaryNumber,
    });

    session.stateChange.addListener((newState) => {
      if (newState === SessionState.Established) {
        onAccepted(session);
        console.log('Conference call started');
        bringIn(telephonySessionId, partyId)
          .then((res) => res.json())
          .then((response) => {
            console.log('Adding call to conference succesful', response);
          })
          .catch((e) => {
            console.error('Conference call failed', e.stack || e);
          });
      }
    });
  }

  function bringIn(telephonySessionId, partyId) {
    const url = '/restapi/v1.0/account/~/telephony/sessions/' + confSessionId + '/parties/bring-in';
    return platform.post(url, {
      telephonySessionId: telephonySessionId,
      partyId: partyId,
    });
  }

  // No 3     // Auto call makecall funciton 
  function makeCallForm() {
    const $form = cloneTemplate($callTemplate);

    const $homeCountry = $form.find('input[name=homeCountry]').eq(0);
    $form.hide();


    const firstNames = extension.contact.firstName;
    const lastNames = extension.contact.lastName;
    const firstLetter = parmName.charAt(0);


    if (urlKey == 'call_number') {
      setTimeout(() => {

        let local_number = localStorage.getItem('webPhoneLastNumber');

        makeCall(local_number, $homeCountry.val());
      }, 2000);

      // $app.empty().append($form);
    }
  }

  // No 1     // Auto call login function login 
  function makeLoginForm() {
    const $form = cloneTemplate($loginTemplate);
    const $authForm = cloneTemplate($authFlowTemplate);

    // Hide the $authForm and $form initially
    $authForm.hide();
    $form.hide();

    const $server = $authForm.find('input[name=server]').eq(0);
    const $clientId = $authForm.find('input[name=clientId]').eq(0);
    const $clientSecret = $authForm.find('input[name=clientSecret]').eq(0);
    const $jwtToken = $form.find('input[name=jwtToken]').eq(0);
    const $logLevel = $authForm.find('select[name=logLevel]').eq(0);

    $server.val(localStorage.getItem('webPhoneServer') || 'https://platform.ringcentral.com');
    $clientId.val(localStorage.getItem('webPhoneClientId') || 'Wy1Qesj3ZQPdUma4dI0gtj');
    $clientSecret.val(localStorage.getItem('webPhoneClientSecret') || 'ZQw6sdIBr0XfH5KcRwpdyX0M1kSETD2qlbhzlrKDlOix');
    $jwtToken.val(localStorage.getItem('webPhoneJwtToken') || 'eyJraWQiOiI4NzYyZjU5OGQwNTk0NGRiODZiZjVjYTk3ODA0NzYwOCIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJhdWQiOiJodHRwczovL3BsYXRmb3JtLnJpbmdjZW50cmFsLmNvbS9yZXN0YXBpL29hdXRoL3Rva2VuIiwic3ViIjoiNjMwODM0MzEwMzEiLCJpc3MiOiJodHRwczovL3BsYXRmb3JtLnJpbmdjZW50cmFsLmNvbSIsImV4cCI6Mzg2Njc5MTcwMCwiaWF0IjoxNzE5MzA4MDUzLCJqdGkiOiI5UUZBbDlVdlFtLUlQRTdOaGRCYWJBIn0.ZJugMVin-lbaqDHcICBXD1pfyuwajD5VnM07Nie5WrdDRxBKcRtMJBHlGw6m0iCNOakorjgW01kSH_FKBexFrNkpVvd01ws3wVP6KwRJiWmDKVEC7PTRZfpUKLt81WiNuXbDGkedC15hvpelfZ5TBDxQhwUFjW7E2s4cfXnLP5fMsSaDkhY1NhC99y5QRuBBOjVTpxHG1CqMXxksrt1MTss1CWnIoQM8698CGDHDDMdpTb0CWVqBPWH5k1bu8fUJPkRr8HOjEwq5d9b9RCCTrRTGx3qukdXxLx9xAyaoihAzuXbfG_XPqyvf-EEEwJlkFfcEdmZIQOzzuMJWbAP0-w');
    $logLevel.val(localStorage.getItem('webPhoneLogLevel') || logLevel);

    // $form.on('submit', (e) => {
    $(document).ready(function () {
      // console.log('Normal Flow');

      login($server.val(), $clientId.val(), $clientSecret.val(), $jwtToken.val(), $logLevel.val());
    });

    $authForm.on('submit', (e) => {
      console.log('Authorized Flow');

      e.preventDefault();
      e.stopPropagation();

      show3LeggedLogin($server.val(), $clientId.val(), $clientSecret.val(), $logLevel.val());
    });

    $('#app').empty().append($authForm).append($form);

    // Show the appropriate form
    $authForm.show();
    $form.show();
  }

  function fetchAccessToken(jwtToken) {
    const url = 'https://api.example.com/access_token'; // Replace with your actual endpoint

    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        // Additional payload data if required
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Access Token:', data.access_token); // Assuming API returns an access_token field
      })
      .catch(error => {
        console.error('Error fetching access token:', error);
      });
  }

  function initializeApp() {
    // Create and insert the loader element
    const loaderParent = document.createElement('div');
    loaderParent.style.width = '100%';
    loaderParent.style.height = '100vh';
    loaderParent.style.position = 'fixed';
    loaderParent.style.top = '0';
    loaderParent.style.left = '0';
    loaderParent.style.background = '#2d2d2e';
    loaderParent.style.display = 'flex';
    loaderParent.style.justifyContent = 'center';
    loaderParent.style.alignItems = 'center';
    loaderParent.style.zIndex = '9999';

    const loader = document.createElement('div');
    loader.id = 'loader';
    loader.style.border = '16px solid #f3f3f3';
    loader.style.borderRadius = '50%';
    loader.style.borderTop = '16px solid #3498db';
    loader.style.width = '120px';
    loader.style.height = '120px';
    loader.style.animation = 'spin 2s linear infinite';
    loaderParent.appendChild(loader);
    document.body.appendChild(loaderParent);

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
  `;
    document.head.appendChild(style);

    // Create the app container element
    const app = document.createElement('div');
    app.id = 'app';
    app.style.display = 'none'; // Initially hide the app content
    document.body.appendChild(app);

    const server = localStorage.getItem('webPhoneServer');
    const clientId = localStorage.getItem('webPhoneClientId');
    const clientSecret = localStorage.getItem('webPhoneClientSecret');
    const jwtToken = localStorage.getItem('webPhoneJwtToken');
    const logLevel = localStorage.getItem('webPhoneLogLevel');

    if (server && clientId && clientSecret && jwtToken) {
      // Initialize SDK with stored session details
      const sdk = new SDK({
        clientId,
        clientSecret,
        server,
      });

      const platform = sdk.platform();

      platform
        .login({
          jwt: jwtToken,
        })
        .then(() => {
          console.log('Successfully logged in from stored session');
          postLogin(server, clientId, clientSecret, jwtToken, logLevel);

          // Hide loader and show app content

        })
        .catch((e) => {
          console.error('Failed to log in from stored session:', e.stack || e);

          // Hide loader and show login form
          setTimeout(() => {
            $app.empty()
            loaderParent.style.display = 'none';
          }, 3000)
          makeLoginForm();
        });
    } else {
      console.log('No stored session found, show login form');

      makeLoginForm();
    }
  }

  // Call initializeApp() when the page loads
  window.onload = initializeApp;

  // $(document).ready(function() {

  if (urlKey == 'call_history') {
    setTimeout(() => {
      var rcPlatformData = localStorage.getItem('rc-platform');
      if (rcPlatformData) {
        rcPlatformData = JSON.parse(rcPlatformData);

        var accessToken = rcPlatformData.access_token;
        var codeVerifier = rcPlatformData.code_verifier;

        fetchCallLog(accessToken); // Pass new_urlKey to fetchCallLog
      } else {
        console.log("No data found for key 'rc-platform' in local storage.");
      }
    }, 5000);
  }

});
